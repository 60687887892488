<template lang="pug">
.container-fluid.px-3
  .row.mb-4
    .col
      h1.mt-1.beta
        span {{ $t('spamProtection') }}
  .row
    .col-5.col-lg-3.mt-1
      .form-group.row.mb-4
        .col-12
          om-switch#spamProtection(v-model.sync="spam")
      .form-group.row
        .col-12
          om-button(primary @click="save()" ref="spamSaveBtn") {{ $t('save') }}
</template>

<script>
  import { mapGetters } from 'vuex';
  import UPDATE_SETTINGS from '@/graphql/UpdateSettings.gql';

  export default {
    data() {
      return {
        spam: false,
      };
    },
    computed: {
      ...mapGetters(['spamProtection']),
    },
    mounted() {
      this.spam = this.spamProtection;
    },
    methods: {
      async save() {
        const {
          data: { updateSettings },
        } = await this.$apollo.mutate({
          mutation: UPDATE_SETTINGS,
          variables: {
            input: {
              spamProtection: this.spam,
            },
          },
        });

        if (updateSettings) {
          this.$notify({
            type: 'success',
            text: this.$t('notifications.saveSuccess'),
          });
          this.$refs.spamSaveBtn.$el.blur();
          return;
        }

        this.$notify({
          type: 'error',
          text: this.$t('notifications.saveError'),
        });
      },
    },
  };
</script>

<style lang="sass">
  .setting-tab-page
    h1
      &.beta
        span
          position: relative
          &:after
            content: "Beta"
            position: absolute
            top: 0
            right: -2.625rem
            color: white
            text-transform: uppercase
            font-size: 0.625rem
            line-height: 1
            background: #8445e2
            padding: 0.1875rem 0.375rem
            text-align: center
            z-index: -1
            pointer-events: none
</style>
